/* global fbq */
import clsx from 'clsx';
import * as React from 'react';
import ReactPlayer from 'react-player';

import { Button } from '@components/Button';
import { HeroProps } from '@components/Hero';
import * as gtag from '@helper/gtag';

import { Image } from './Image';
import * as styles from './styles.module.scss';

// @todo make use of onClick
export const TwoColumns: React.FunctionComponent<HeroProps> = ({ headline, text, ctaText, ctaLink, media }) => {
	const { video, image } = JSON.parse(media);

	const handleClick = React.useCallback(() => {
		gtag.event({
			action: 'click',
			category: 'button',
			label: 'calendly'
		});

		fbq('track', 'Lead');
	}, []);

	return (
		<div className={clsx(styles.heroBackdrop, video && styles.withMedia)}>
			<div className={clsx(styles.heroWrapper, video && styles.withMedia)}>
				<div className={clsx(styles.heroContent, video && styles.withMedia, image && styles.withImage, image.alternateRatio && styles.alternativeRatio)}>
					{video &&
						<div className={styles.heroVideo}>
							<ReactPlayer
								// eslint-disable-next-line react/forbid-component-props
								style={{
									position: 'absolute'
								}}
								url={video.url}
								width="100%"
								height="100%"
							/>
						</div>
					}
					{image &&
						<div className={clsx(styles.heroImageWrapper, image.alternateRatio && styles.alternativeRatio)}>
							<Image className={clsx(styles.heroImage, image.alternateRatio && styles.alternativeRatio)} image={image} />
						</div>
					}
					<div className={clsx(styles.heroText, image.alternateRatio && styles.alternativeRatio)}>
						<h1>{headline}</h1>
						<p>{text}</p>
						{ctaText && <Button role="primary" inverted={true} type="button" target="_blank" link={ctaLink} onClick={handleClick}>{ctaText}</Button>}
					</div>
				</div>
			</div>
		</div>
	);
};
