import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import * as Recharts from 'recharts';

import { Wrapper } from '@components/Wrapper';

import * as styles from './styles.module.scss';

interface ChartProps {}

export const Chart: React.FunctionComponent<ChartProps> = () => {
	const title1 = 'ohne Retention Excellence';
	const title2 = 'mit Retention Excellence';
	const data = [
		{
			name: 'Mai',
			[title1]: 27,
			[title2]: 24
		},
		{
			name: 'Juni',
			[title1]: 38,
			[title2]: 35
		},
		{
			name: 'Juli',
			[title1]: 50,
			[title2]: 38
		},
		{
			name: 'August',
			[title1]: 26,
			[title2]: 23
		}
	];
	const { ref, inView } = useInView({
		threshold: 0.5
	});

	return (
		<Wrapper>
			<div ref={ref} className={styles.chart}>
				<div className={styles.text}>
					<div>
						<h3>
							Kundenfeedback effektiv nutzen
						</h3>
					</div>
					<p>
						Mit Retention Excellence kannst Du auf Kundenfeedback schnell und effizient reagieren.<br /> So können Kündigungen um bis zu 20% reduziert werden und Deine Mitglieder bleiben langfristig zufriedene Mitglieder.
					</p>
				</div>
				<div>
					<Recharts.ResponsiveContainer height={340}>
						<Recharts.BarChart data={data}>
							<Recharts.XAxis dataKey="name" />
							<Recharts.Bar dataKey={title1} fill="#07192e" />
							<Recharts.Bar dataKey={title2} fill="#05c5f7" />
							{inView && <Recharts.Legend />}
						</Recharts.BarChart>
					</Recharts.ResponsiveContainer>
				</div>
			</div>
		</Wrapper>
	);
};
