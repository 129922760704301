/* global fbq */
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';

import img from '@assets/images/logo_small.png';
import { Button } from '@components/Button';
import { Navigation } from '@components/Navigation';
import * as gtag from '@helper/gtag';
import { useScrollPosition } from '@hooks/useScrollposition';

import * as styles from './styles.module.scss';

interface NavigationProps {
	activeAreaId?: string;

	menuLinks: {
		href: string;
		title: string;
	}[];

	ctaText: string;
	ctaLink?: string;
}

export const Header: React.FunctionComponent<NavigationProps> = ({ menuLinks, ctaText, ctaLink }) => {
	const [isScrolling, setIsScrolling] = React.useState(false);
	useScrollPosition(({ currentPosition }) => ((currentPosition.y > 0) ? setIsScrolling(true) : setIsScrolling(false)), []);

	const handleCtaClick = React.useCallback(() => {
		gtag.event({
			action: 'click',
			category: 'button',
			label: 'calendly'
		});

		fbq('track', 'Lead');
	}, []);

	return (
		<header className={clsx(styles.backdrop, isScrolling && styles.isScrolling)}>
			<div className={clsx(styles.header, isScrolling && styles.isScrolling)}>
				<div className={styles.logoWrap}>
					<img src={img} />
				</div>
				<div className={styles.linkList}>
					{menuLinks.map(({ href, title }) => ((/^\/(?!\/)/u).test(href) ? <Link to={href}>{title}</Link> : <a key={`${href}-${title}`} href={href}>{title}</a>))}
				</div>
				<Button role="textual" target="_blank" type="button" inverted={true} link={ctaLink} onClick={handleCtaClick}>
					{ctaText}
				</Button>
				<Navigation menuLinks={menuLinks} ctaText={ctaText} ctaLink={ctaLink} />
			</div>
		</header>
	);
};
