import * as React from 'react';

import { BackgroundImage } from './BackgroundImage';
import { TwoColumns } from './TwoColumns';

export interface HeroProps {
	headline: string;
	text: string;
	ctaText?: string;
	ctaLink?: string;
	layout: 'twoCols'|'backgroundImage';
	media: string;

	onClick? (): void;
}

export const Hero: React.FunctionComponent<HeroProps> = (props) => {
	switch (props.layout) {
		case 'backgroundImage':
			return <BackgroundImage {...props} />;
		case 'twoCols':
			return <TwoColumns {...props} />;
		default:
			return <div>No layout option set</div>;
	}
};
