import * as React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import { Wrapper } from '@components/Wrapper';
import { uniqueId } from '@helper/uniqueId';

import * as styles from './styles.module.scss';

interface MetricsProps {
	cards: {
		title: string;
		metric: number;
		metricPrefix?: string;
		metricSuffix?: string;
	}[];
}

export const Metrics: React.FunctionComponent<MetricsProps> = ({ cards }) => {
	const { ref, inView } = useInView({
		threshold: 0.5
	});

	return (
		<div ref={ref} className={styles.metricsBackdrop}>
			<Wrapper>
				<div className={styles.metrics}>
					{cards.map(({ metricPrefix, metricSuffix, title, metric }) => (
						<div key={uniqueId()} className={styles.metricsCards}>
							{typeof window !== 'undefined' ?
								<span className={styles.metricsCardsNumber}>{inView && <CountUp end={metric} useEasing={true} duration={0.750} suffix={metricSuffix ? metricSuffix : ''} prefix={metricPrefix ? metricPrefix : ''} />}</span>
								:
								<span className={styles.metricsCardsNumber}>{`${metricSuffix ? metricSuffix : ''}${metric}${metricPrefix ? metricPrefix : ''}`}</span>
							}
							<span className={styles.metricsCardsTilte}>{title}</span>
						</div>
					))}
				</div>
			</Wrapper>
		</div>
	);
};
