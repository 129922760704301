import * as React from 'react';

export function useParamString (link: string) {
	const [taggedUrl, setTaggedUrl] = React.useState(link);

	React.useEffect(() => {
		const urlParams = typeof window !== 'undefined' && window.location.search.substring(1);
		const params: any = urlParams && Array.from(new URLSearchParams(urlParams).entries()).reduce((acc, current) => {
			acc[current[0]] = current[1];

			return acc;
		}, {});

		setTaggedUrl(`${link}?utm_source=${params.utm_source ?? ''}&utm_medium=${params.utm_medium ?? ''}`);
	}, []);

	return taggedUrl;
}
