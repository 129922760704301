/* global Content */
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

interface ImageProps {
	image: Content.ImageObject;
	mobileImage?: Content.ImageObject;
	className?: string;
	imgClassName?: string;
}

export const Image: React.FunctionComponent<ImageProps> = ({ image, className, imgClassName }) => {
	const files = useStaticQuery(graphql`
		query {
			desktop: allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
		}
	`);

	const source = getImageByName(files.desktop, image.imageName);

	if (!source) {
		throw new Error('This Image does not exist!');
	}

	return <GatsbyImage className={className} imgClassName={imgClassName} image={source} alt={image.altText ?? ''} />;
};

function getImageByName (files: any, name: string) {
	return getImage(files.edges.find((imageEntry: any) => imageEntry.node.name === name).node);
}
