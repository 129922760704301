/* global gtag */
/* eslint-disable @typescript-eslint/naming-convention */
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
	gtag('config', 'UA-208677921-1', {
		page_path: url
	});
};

interface GtagProps {
	action: string;
	category: string;
	label: string;
	value?: string;
}
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GtagProps) => {
	gtag('event', action, {
		event_category: category,
		event_label: label,
		value
	});
};
