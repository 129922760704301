import clsx from 'clsx';
import * as React from 'react';

import * as styles from './styles.module.scss';

interface WrapperProps {
	id?: string;
	className?: string;
}

export const Wrapper: React.FunctionComponent<WrapperProps> = ({ children, id, className }) => (
	<div id={id} className={clsx(styles.wrapper, className)}>
		{children}
	</div>
);
