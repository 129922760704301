/* global fbq */
import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Button } from '@components/Button';
import * as gtag from '@helper/gtag';

import * as styles from './styles.module.scss';

interface CtaAreaProps {
	headline: string;
	subline: string;
	ctaText: string;
	ctaLink: string;
	ctaCaption: string;
	imageName?: string;
	onCLick? (): void;
}

export const CtaArea: React.FunctionComponent<CtaAreaProps> = ({ headline, subline, ctaText, imageName, ctaCaption, ctaLink }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	`);
	const source = imageName && files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === imageName).node.childImageSharp.gatsbyImageData;
	const handleClick = React.useCallback(() => {
		gtag.event({
			action: 'click',
			category: 'button',
			label: 'calendly'
		});

		fbq('track', 'Lead');
	}, []);
	return (
		<div className={clsx(styles.ctaAreaBackdrop)}>
			{source &&
				<GatsbyImage
					image={source}
					className={styles.ctaAreaImg}
					alt=""
				/>
			}
			<div className={styles.ctaAreaWrapper}>
				<div>
					{headline && <h2>{headline}</h2>}
					{subline && <h3>{subline}</h3>}
				</div>
				<Button type="button" target="_blank" role="primary" inverted={true} link={ctaLink} onClick={handleClick}>
					{ctaText}
				</Button>
				<div className={styles.ctaAreaButton}>
					{ctaCaption}
				</div>
			</div>
		</div>
	);
};
