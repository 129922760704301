/* global Content */
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { SVGIcon } from '@components/SVGIcon';

import * as styles from './styles.module.scss';

export interface CardProps {
	iconName?: string;
	image?: Content.ImageObject;
	frame?: boolean;
	className?: string;
}

export const Card: React.FunctionComponent<CardProps> = ({ children, iconName, image, frame, className, ...restProps }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.7777777777)
						}
					}
				}
			}
		}
	`);
	const source = image && getImage(files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === image.imageName).node);

	return (
		<div className={clsx(className ?? styles.template, styles.card, frame && styles.frame)} {...restProps}>
			{(iconName || source) &&
				<div className={styles.header}>
					{source && <GatsbyImage className={styles.image} image={source} alt={image?.altText ?? ''} />}
					{iconName &&
						<div className={clsx(styles.icon, source && styles.centered)}>
							<SVGIcon name={iconName as any} />
						</div>
					}
				</div>
			}
			{children}
		</div>
	);
};
