
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '@components/Footer';
import { Header } from '@components/Header';

import * as styles from './styles.module.scss';

interface SiteWrapperProps {
	data: any;
}

export const SiteWrapper: React.FunctionComponent<SiteWrapperProps> = ({ children, data }) => (
	<div className={styles.siteWrapper}>
		<Helmet>
			<title>{data.meta.title}</title>
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap" rel="stylesheet" />
			<script type="text/javascript" charSet="UTF-8" src="//cdn.cookie-script.com/s/a55d7d8e2532509184640f8f046a1889.js" />
			<meta name="description" content={data.meta.description} />
		</Helmet>
		<Header {...data.navigation} />
		<div className={styles.siteContent}>
			{children}
		</div>
		<Footer {...data.footer} />
	</div>
);
