import clsx from 'clsx';
import * as React from 'react';

import { Wrapper } from '@components/Wrapper';
import { uniqueId } from '@helper/uniqueId';

import { ListElement, ImageProps, VideoProps } from './ListElement';
import * as styles from './styles.module.scss';

interface ContentListProps {
	headline?: string;
	subline?: string;
	text?: string;
	id?: string;
	backgroundImage?: boolean;
	content: (ImageProps | VideoProps)[];
}

export const ContentList: React.FunctionComponent<ContentListProps> = ({ content, headline, subline, text, id, backgroundImage }) => (
	<Wrapper id={id}>
		<div className={styles.content}>
			{(headline || subline || text) &&
				<div className={styles.contentHeader}>
					{subline && <h3>{subline}</h3>}
					{headline && <h2>{headline}</h2>}
					{text && <p>{text}</p>}
				</div>
			}
			<div className={clsx(styles.contentList, backgroundImage && styles.background)}>
				{content.map((props) => <ListElement key={uniqueId()} {...props} />)}
			</div>
		</div>
	</Wrapper>
);
