// extracted by mini-css-extract-plugin
export var heroContent = "styles-module--hero-content--19hK5";
export var withMedia = "styles-module--with-media--3i03x";
export var withImage = "styles-module--with-image--2MFht";
export var alternativeRatio = "styles-module--alternative-ratio--1JIiP";
export var heroBackdrop = "styles-module--hero-backdrop--2VCUs";
export var heroVideo = "styles-module--hero-video--3obIP";
export var heroVideoIframe = "styles-module--hero-video-iframe--1F8rM";
export var heroImage = "styles-module--hero-image--cim-j";
export var heroImageWrapper = "styles-module--hero-image-wrapper--2V-iz";
export var heroText = "styles-module--hero-text--3gX6S";
export var heroWrapper = "styles-module--hero-wrapper--1IoL3";