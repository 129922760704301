import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { Wrapper } from '@components/Wrapper';

import * as styles from './styles.module.scss';

interface CenteredParagraphProps {
	paragraphs: string[];
	imageName: string;
	headline: string;

}

export const CenteredParagraph: React.FunctionComponent<CenteredParagraphProps> = ({ imageName, paragraphs, headline }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	`);
	const image = imageName && files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === imageName).node.childImageSharp.gatsbyImageData;

	return (
		<div className={styles.paragraphsWrapper}>
			<Wrapper>
				<div className={styles.paragraphs}>
					<h2>{headline}</h2>
					<div>
						{paragraphs.map((paragraph) => <p key={paragraph}>{paragraph}</p>)}
					</div>
					<GatsbyImage
						className={styles.paragraphsImage}
						image={image}
						alt=""
					/>
				</div>
			</Wrapper>
		</div>
	);
};
