import * as React from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';

import { SVGIcon } from '@components/SVGIcon';
import { Wrapper } from '@components/Wrapper';
import { uniqueId } from '@helper/uniqueId';

import * as styles from './styles.module.scss';

interface AccordionProps {
	headline: string;
	items: {
		buttonText: string;
		panelContent: string;
	}[];
}

export const CustomAccordion: React.FunctionComponent<AccordionProps> = ({ headline, items }) => (
	<Wrapper>
		<h2 className={styles.headline}>{headline}</h2>
		<Accordion className={styles.accordion} allowZeroExpanded={true}>
			{items.map(({ buttonText, panelContent }) => (
				<AccordionItem key={uniqueId()} className={styles.accordionItem}>
					<AccordionItemHeading className={styles.accordionHeader}>
						<AccordionItemButton className={styles.accordionButton}>
							<span>{buttonText}</span>
							<SVGIcon name="plus" />
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel className={styles.accordionContent}>
						<p>
							{panelContent}
						</p>
					</AccordionItemPanel>
				</AccordionItem>
			))}
		</Accordion>
	</Wrapper>
);
