/* global fbq */
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';

import { Button } from '@components/Button';
import { SVGIcon } from '@components/SVGIcon';
import * as gtag from '@helper/gtag';

import * as styles from './styles.module.scss';

interface NavigationProps {
	ctaText: string;
	ctaLink?: string;
	menuLinks: {
		href: string;
		title: string;
	}[];
}

export const Navigation: React.FunctionComponent<NavigationProps> = ({ menuLinks, ctaText, ctaLink }) => {
	const [menuOpen, setMenuOpen] = React.useState(false);
	const handleClick = React.useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);

	const handleCtaClick = React.useCallback(() => {
		gtag.event({
			action: 'click',
			category: 'button',
			label: 'calendly'
		});

		fbq('track', 'Lead');
	}, []);

	return (
		<div className={styles.menu}>
			<div className={styles.menuHandle} onClick={handleClick}><SVGIcon name="bars" /></div>
			<div className={clsx(styles.menuLinks, menuOpen && styles.active)}>
				<div className={styles.menuHeader}>
					<Button role="textual" target="_blank" type="button" inverted={true} link={ctaLink} onClick={handleCtaClick}>
						{ctaText}
					</Button>
					<div className={styles.menuHandle} onClick={handleClick}>
						<SVGIcon name="times" />
					</div>
				</div>
				<div className={styles.linkList}>
					{menuLinks.map(({ href, title }) => ((/^\/(?!\/)/u).test(href) ? <Link to={href} onClick={handleClick}>{title}</Link> : <a key={`${href}-${title}`} href={href} onClick={handleClick}>{title}</a>))}
				</div>
			</div>
		</div>
	);
};
