import * as React from 'react';

import * as styles from './styles.module.scss';

interface BreakerInterface {}

export const Breaker: React.FunctionComponent<BreakerInterface> = () => (
	<div className={styles.breaker}>
		<div className={styles.breakerElement} />
	</div>
);
