import clsx from 'clsx';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { Wrapper } from '@components/Wrapper';

import * as styles from './styles.module.scss';

interface AnimatedScreensProps {
	headline: string;
	subline: string;
	text: string;
	id?: string;
	imageNames: string[];
}

export const AnimatedScreens: React.FunctionComponent<AnimatedScreensProps> = ({ headline, subline, text, imageNames, id }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
		}
	`);
	const sources = imageNames.map((name) => files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === name).node.childImageSharp.gatsbyImageData);
	const { ref, inView } = useInView({
		threshold: 0.5
	});

	return (
		<Wrapper id={id}>
			<div className={styles.intro} >
				<h4>{subline}</h4>
				<h2>{headline}</h2>
				<p>{text}</p>
			</div>
			<div ref={ref} className={styles.container}>
				<div className={clsx(styles.first, styles.screen, inView && styles.active)}><GatsbyImage image={sources[1]} alt="" /></div>
				<div className={styles.screen}><GatsbyImage image={sources[0]} alt="" /></div>
				<div className={clsx(styles.last, styles.screen, inView && styles.active)}><GatsbyImage image={sources[2]} alt="" /></div>
			</div>
		</Wrapper>
	);
};
